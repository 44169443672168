<template>
  <div>
    <el-scrollbar class="height-table" v-loading="loading">
      <el-table
        :data="users"
        border
        stripe
        style="width: 100%; word-break: break-word"
        empty-text="Không có dữ liệu"
        :header-cell-style="{ background: 'white' }"
        @row-click="clickRow($event)"
      >
        <el-table-column>
          <!-- Header -->
          <template #header>
            <div class="flex justify-between">
              <div class="flex justify-start items-center gap-2">
                <search-input
                  v-model="search"
                  class="w-96"
                  :isIcon="true"
                  placeholder="Tìm kiếm theo tên hoặc email"
                  @inputSearch="search = $event"
                  @doneTyping="searchInput()"
                />
                <span
                  class="flex items-center gap-2"
                  v-if="
                    getSelectedCompany.owner &&
                    getSelectedCompany.owner.emailQuanLyTaiKhoan
                  "
                >
                  <UserIcon :user="getSelectedCompany.owner" />
                  {{ getSelectedCompany.owner.emailQuanLyTaiKhoan }}
                </span>
              </div>
              <div class="flex items-center gap-2 ml-2">
                <UserAdd :roles="roles" @users:updated="getListUser" />
              </div>
            </div>
          </template>

          <!-- Body -->
          <el-table-column
            type="index"
            label="STT"
            :index="indexMethod"
            width="50"
            align="center"
          />

          <el-table-column prop="emailQuanLyTaiKhoan" label="Email">
            <template #default="scope">
              <div class="flex justify-between items-center">
                <span>{{ scope.row.emailQuanLyTaiKhoan }}</span>
                <div class="flex gap-2 text-right items-center">
                  <div
                    v-if="getSelectedCompany.id == -1"
                    class="
                      bg-gradient-to-r
                      p-1
                      rounded
                      from-gray-200
                      via-gray-200
                      to-gray-100
                      w-12
                      text-center
                    "
                    :class="[
                      { 'from-indigo-500': scope.row.plan == 'premium' },
                      { 'via-purple-500': scope.row.plan == 'premium' },
                      { 'to-pink-500': scope.row.plan == 'premium' },
                      { 'text-white': scope.row.plan == 'premium' },
                      { 'w-20': scope.row.plan == 'premium' },
                    ]"
                  >
                    <span class="capitalize font-semibold">{{
                      scope.row.plan
                    }}</span>
                  </div>
                  <UserIcon :user="scope.row" />
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label=""
            align="left"
            v-if="getSelectedCompany.id == -1"
          >
            <template #header>Công ty</template>
            <template #default="scope">
              <div
                class="flex"
                v-for="company in scope.row.company.owner.slice(0, 3)"
                :key="company[1]"
              >
                <span>
                  {{ company[0] }} ~
                  {{ company[1] }}
                  <img
                    src="@/assets/icons/key.svg"
                    alt=""
                    class="inline-block h-5 w-5"
                  />
                </span>
              </div>
              <small v-if="scope.row.company.owner.length > 3"
                >(Chọn để xem chi tiết)</small
              >

              <hr
                class="py-2"
                v-if="
                  scope.row.company.user.length > 0 &&
                  scope.row.company.owner.length > 0
                "
              />
              <div
                class="flex"
                v-for="company in scope.row.company.user.slice(0, 5)"
                :key="company[1]"
              >
                <span>
                  {{ company[0] }} ~
                  {{ company[1] }}
                </span>
              </div>
              <small v-if="scope.row.company.user.length > 5"
                >(Chọn để xem chi tiết)</small
              >
            </template>
          </el-table-column>

          <el-table-column
            label="Vai trò"
            width="250"
            align="left"
            v-if="getSelectedCompany.id != -1"
          >
            <template #default="props">
              {{
                props.row.roles
                  ? props.row.roles.map((item) => item["role__name"]).join(", ")
                  : ""
              }}
            </template>
          </el-table-column>

          <el-table-column prop="hoTen" label="Tên" width="200">
            <template #default="props">
              <div class="flex justify-between items-center">
                <p>{{ props.row.hoTen }}&nbsp;</p>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="soDienThoai"
            label="Số điện thoại"
            align="center"
            width="150"
          />

          <el-table-column
            prop="expired_at"
            label="Ngày hết hạn"
            align="center"
            width="150"
          >
            <template #default="props">
              <span v-if="props.row.expired_at">
                {{ formatDate(props.row.expired_at) }}
              </span>
              <span v-else> Chưa kích hoạt </span>
            </template>
          </el-table-column>

          <el-table-column
            label=""
            align="center"
            width="90"
            v-if="getSelectedCompany.id != -1"
          >
            <template #header>Xem/sửa</template>
            <template #default="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                circle
                :disabled="
                  getAuthUser.emailQuanLyTaiKhoan ==
                  scope.row.emailQuanLyTaiKhoan
                "
              >
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            label=""
            align="center"
            width="60"
            v-if="getSelectedCompany.id != -1"
          >
            <template #header>Xóa</template>
            <template #default="scope">
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                circle
                @click="removeUser(scope.row)"
                :disabled="
                  getAuthUser.emailQuanLyTaiKhoan ==
                  scope.row.emailQuanLyTaiKhoan
                "
              >
              </el-button>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-scrollbar>

    <div>
      <paginate
        :currentPage="paginate.currentPage"
        :totalItem="paginate.totalItem"
        :totalPage="paginate.totalPage"
        :limit="paginate.limit"
        @modifiedCurrentPage="handleCurrentPageChange($event)"
        @update:limit="handleLimitUpdate"
      />
    </div>

    <UserEdit
      :user="selectedUser"
      :roles="roles"
      :open="showEditModal"
      @showEditModal:closed="showEditModal = false"
      @users:updated="getListUser"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { URL } from "@/api/users";
import formatDate from "@/plugins/formatDate.js";
import formatTime from "@/plugins/formatTime.js";
import UserAdd from "@/views/pages/users/user/add.vue";
import UserEdit from "@/views/pages/users/user/edit.vue";
import SearchInput from "@/components/searchInput";
import Paginate from "@/components/paginate.vue";
import UserIcon from "@/components/UserIcon.vue";

export default {
  components: {
    UserAdd,
    UserEdit,
    Paginate,
    SearchInput,
    UserIcon,
  },
  data() {
    return {
      users: [],
      search: null,
      paginate: {
        limit: 20,
        totalItem: null,
        currentPage: 1,
        totalPage: 1,
      },
      showEditModal: false,
      isRemoveUser: false,
      loading: false,
      selectedUser: {},
      roles: [],
    };
  },

  computed: {
    ...mapGetters(["getSelectedCompany", "getAuthUser"]),
  },

  async created() {
    this.search = this.getUserSearch;
    this.getListRoleUser();
    await this.getListUser();
  },

  methods: {
    async getListRoleUser() {
      const params = {
        owner_id: this.getSelectedCompany.owner
          ? this.getSelectedCompany.owner.id
          : null,
      };
      const result = await this.$request({
        url: URL.ROLES_USER,
        params,
        ignore401: true,
      });
      const res = result.data;

      if (res.success && res.code == 200) {
        this.roles = res.data;
      }
    },

    async getListUser() {
      if (this.getSelectedCompany.id != -1) {
        this.loading = true;
      }
      const params = {
        company_id: this.getSelectedCompany.id,
        page: this.paginate.currentPage,
        limit: this.paginate.limit,
      };
      if (this.search) {
        params.key_search = this.search;
      }

      const result = await this.$request({
        url: URL.LIST_USER,
        params,
      });
      const res = result.data;

      if (res.success && res.code == 200) {
        this.users = res.data.data;
        this.paginate.totalPage = res.data.total_pages;
        this.paginate.totalItem = res.data.total;
        this.paginate.limit = res.data.limit;
      }

      this.loading = false;
    },

    handleCurrentPageChange($event) {
      this.paginate.currentPage = $event;
      this.getListUser();
    },
    handleLimitUpdate(limit) {
      this.paginate.limit = limit;
      this.paginate.currentPage = 1;
      this.getListUser();
    },
    searchInput() {
      this.paginate.totalPage = 1;
      this.getListUser();
    },

    removeUser(user) {
      this.isRemoveUser = true;
      this.$swal({
        title: "Xác nhận xóa người dùng",
        html: `Thao tác này sẽ xóa người dùng: <b>${user.emailQuanLyTaiKhoan}</b> ra khỏi công ty <b>${this.getSelectedCompany.tenDoanhNghiep}</b>`,
        icon: "warning",
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        showCancelButton: true,
        reverseButtons: true,
        timer: 15000,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            company_id: this.getSelectedCompany.id,
            role_ids: [],
          };
          const result = await this.$request({
            url: URL.UPDATE_COMPANY_USER.replace(":id", user.id),
            method: "PUT",
            data,
          });

          const res = result.data;
          if (res.code == 200 && res.success) {
            this.getListUser();
            this.$swal({ text: "Xóa người dùng thành công" });
          }
        }
        this.isRemoveUser = false;
      });
      return "";
    },

    clickRow(user) {
      if (!this.isRemoveUser) {
        this.showEditModal = true;
        this.selectedUser = user;
      }
    },

    indexMethod(index) {
      return index + 1 + (this.paginate.currentPage - 1) * this.paginate.limit;
    },

    formatDate,
    formatTime,
  },

  watch: {
    getSelectedCompany() {
      this.getListRoleUser();
      this.getListUser();
    },
  },
};
</script>
