<template>
  <div>
    <div class="flex justify-between">
      <div>
        Thời gian
        <el-date-picker
          v-model="userLoggingPaginate.date"
          type="daterange"
          start-placeholder="Ngày bắt đầu"
          end-placeholder="Ngày kết thúc"
          :shortcuts="shortcuts"
          :disabled-date="disabledDate"
        />
      </div>
      <div class="items-center flex">
        <el-button
          class="items-center"
          size="mini"
          type="primary"
          @click="getUserLogging"
        >
          Tìm kiếm
        </el-button>
      </div>
    </div>

    <el-scrollbar
      class="height-table-expand mt-2"
      v-loading="isUserLoggingLoading"
    >
      <el-table
        :data="userLogging"
        row-key="id"
        border
        stripe
        ref="tableData"
        class="user-logging-table"
      >
        <el-table-column
          type="index"
          label="STT"
          width="50"
          align="center"
          :index="indexMethodUserLogging"
        />
        <el-table-column label="Thời gian bắt đầu phiên">
          <template #default="props">
            <div>
              {{ formatDate(props.row.created_at) }} -
              {{ formatTime(props.row.created_at) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Thời gian kết thúc phiên">
          <template #default="props">
            <div>
              {{ formatDate(props.row.updated_at) }} -
              {{ formatTime(props.row.updated_at) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Khoảng thời gian">
          <template #default="props">
            <div>
              {{ durationReadAble(props.row.created_at, props.row.updated_at) }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>

    <paginate
      :currentPage="userLoggingPaginate.currentPage"
      :totalItem="userLoggingPaginate.totalItem"
      :totalPage="userLoggingPaginate.totalPage"
      :limit="userLoggingPaginate.limit"
      @modifiedCurrentPage="handleUserLoggingCurrentPageChange($event)"
      @update:limit="handleUserLoggingLimitUpdate"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { URL } from "@/api/users";
import formatDate from "@/plugins/formatDate.js";
import formatTime from "@/plugins/formatTime.js";
import { durationReadAble } from "@/plugins/common";

export default {
  components: {},
  data() {
    return {
      shortcuts: [
        {
          text: "1 tuần trước",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "2 tuần trước",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
            return [start, end];
          },
        },
        {
          text: "1 tháng trước",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
      ],
      userLoggingPaginate: {
        date: [null, null],
        limit: null,
        totalItem: null,
        currentPage: 1,
        totalPage: 1,
      },
      isUserLoggingLoading: false,
      userLogging: [],
    };
  },

  props: {
    selectedUser: {
      type: Object,
      default: () => {},
    },
  },

  created() {
    this.getUserLogging()
  },

  computed: {
    ...mapGetters(["getSelectedCompany"]),
  },

  methods: {
    disabledDate(time) {
      return time.getTime() > Date.now();
    },

    async getUserLogging() {
      this.isUserLoggingLoading = true;
      const params = {
        page: this.userLoggingPaginate.currentPage,
        limit: this.userLoggingPaginate.limit,
      };
      if (this.userLoggingPaginate.date && this.userLoggingPaginate.date[0]) {
        params["created_at__gte"] = this.userLoggingPaginate.date[0];

        let created_at__lte = new Date(this.userLoggingPaginate.date[1]);
        created_at__lte.setTime(created_at__lte.getTime() + 86400 * 1000);
        params["created_at__lte"] = created_at__lte;
      }
      const result = await this.$request({
        url: URL.GET_USER_LOGGING.replace(":id", this.selectedUser.id),
        params,
      });
      const res = result.data;
      this.userLogging = res.data.data;

      this.userLoggingPaginate.limit = res.data.limit;
      this.userLoggingPaginate.totalItem = res.data.total;
      this.userLoggingPaginate.totalPage = res.data.total_pages;

      this.isUserLoggingLoading = false;
    },
    async handleUserLoggingCurrentPageChange($event) {
      this.userLoggingPaginate.currentPage = $event;
      this.getUserLogging();
    },
    async handleUserLoggingLimitUpdate(limit) {
      this.userLoggingPaginate.limit = limit;
      this.userLoggingPaginate.currentPage = 1;
      this.getUserLogging();
    },
    indexMethodUserLogging(index) {
      return (
        index +
        1 +
        (this.userLoggingPaginate.currentPage - 1) *
          this.userLoggingPaginate.limit
      );
    },

    formatDate,
    formatTime,
    durationReadAble,
  },

  watch: {},
};
</script>
