<template>
  <div>
    <!-- Edit User Modal -->
    <el-dialog
      v-model="showEditModal"
      title="Thông tin người dùng"
      top="9vh"
      class="px-0"
    >
      <el-tabs v-model="selectedTab">
        <el-tab-pane label="Chỉnh sửa" name="detail" class="w-full" />
        <el-tab-pane
          label="Công ty"
          name="Company"
          class="w-full"
          v-if="getAuthUser.is_itax_admin"
        />
        <el-tab-pane label="Lịch sử" name="userLogging" class="w-full" />
      </el-tabs>

      <!-- Edit below -->
      <el-form v-if="selectedTab == 'detail'">
        <el-form-item
          class="w-full relative"
          label="Email"
          :label-width="labelWidth"
        >
          <el-input v-model="selectedUser.emailQuanLyTaiKhoan" disabled />
        </el-form-item>
        <el-form-item class="w-full" label="Họ tên" :label-width="labelWidth">
          <el-input label="Họ tên" v-model="selectedUser.hoTen" disabled />
        </el-form-item>
        <el-form-item
          class="w-full"
          label="Số điện thoại"
          :label-width="labelWidth"
        >
          <el-input v-model="selectedUser.soDienThoai" disabled />
        </el-form-item>

        <hr class="py-3" />
        <el-form-item class="w-full" label="Vai trò" :label-width="labelWidth">
          <el-radio-group v-model="selectedUser.roles" class="grid grid-cols-3">
            <el-radio
              v-for="(role, idx) in roles"
              :key="idx"
              :label="role['id']"
            >
              {{ role["name"] }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- For Owner only -->
        <div v-if="ableToEditPremiumGroup">
          <hr class="py-3" />
          <div class="grid grid-cols-2 gap-3">
            <el-form-item
              class="w-full"
              label="Hóa đơn GH"
              :label-width="labelWidth"
            >
              <el-input
                v-model="selectedUser.invoice_gov_max"
                type="number"
                class="w-32"
              />
            </el-form-item>

            <el-form-item
              class="w-full"
              label="Hóa đơn đã đọc"
              :label-width="labelWidth"
            >
              <el-input v-model="selectedUser.invoice_gov_count" disabled />
            </el-form-item>

            <el-form-item label="Ngày hết hạn" :label-width="labelWidth">
              <el-date-picker
                class="w-full"
                v-model="selectedUser.expired_at"
                type="date"
                :clearable="false"
                placeholder="Chọn ngày..."
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item
              class="w-full"
              label="Gói thành viên"
              :label-width="labelWidth"
            >
              <el-select v-model="selectedUser.plan" style="width: 100%">
                <el-option
                  v-for="item in ['basic', 'premium']"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <!-- For Owner only -->

      <div v-if="selectedTab == 'Company'">
        <!-- Owner Company -->
        <h1 class="text-xl font-semibold mt-2">Công ty làm sở hữu</h1>
        <el-scrollbar class="height-table-expand">
          <el-table :data="selectedUser.company.owner" row-key="0" border>
            <el-table-column
              type="index"
              label="STT"
              width="50"
              align="center"
            />

            <el-table-column prop="0" label="Tên công ty">
              <template #header>
                Tên công ty
                <img
                  src="@/assets/icons/key.svg"
                  alt=""
                  class="inline-block h-5 w-5"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="1"
              label="Mã số thuế"
              align="center"
              width="150"
            />
          </el-table>
        </el-scrollbar>

        <h1 class="text-xl font-semibold mt-5">Công ty làm thành viên</h1>
        <el-scrollbar class="height-table-expand">
          <el-table :data="selectedUser.company.user" row-key="id" border>
            <el-table-column
              type="index"
              label="STT"
              width="50"
              align="center"
            />
            <el-table-column prop="0" label="Tên công ty" />
            <el-table-column prop="1" label="Mã số thuế" width="150" />
          </el-table>
        </el-scrollbar>
      </div>

      <div v-if="selectedTab == 'userLogging'">
        <UserLogging :selectedUser="selectedUser" />
      </div>

      <div class="dialog-footer text-right itax-button mt-3">
        <div class="dialog-footer text-right itax-button mt-3">
          <el-button size="mini" @click="showEditModal = false">
            Đóng
          </el-button>
          <el-button
            size="mini"
            type="primary"
            @click="confirmUpdate"
            v-if="selectedTab == 'detail'"
          >
            Xác nhận
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { URL } from "@/api/users";
import formatDate from "@/plugins/formatDate.js";
import formatTime from "@/plugins/formatTime.js";
import UserLogging from "@/views/pages/users/user/edit.logging.vue";

export default {
  components: {
    UserLogging,
  },
  data() {
    return {
      selectedTab: "detail", // [detail, company, history]
      selectedUser: {},
      showEditModal: false,
      labelWidth: "150px",
    };
  },

  props: {
    roles: {
      type: Array,
      default: null,
    },
    open: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters(["getSelectedCompany", "getAuthUser"]),
    ableToEditPremiumGroup() {
      return this.getAuthUser.is_itax_admin;
    },
  },

  async created() {},

  methods: {
    async confirmUpdate() {
      const data = {
        company_id: this.getSelectedCompany.id,
        invoice_gov_max: this.selectedUser.invoice_gov_max,
        plan: this.selectedUser.plan,
        expired_at: this.selectedUser.expired_at,
      };

      if (this.getSelectedCompany.id > 0) {
        if (this.selectedUser.roles) {
          data["role_ids"] = [this.selectedUser.roles];
        } else {
          this.$swal({
            text: "Vui lòng không để trống vai trò",
            icon: "warning",
          });
          return;
        }
      }

      // ROLE UPDATE
      const result = await this.$request({
        url: URL.UPDATE_COMPANY_USER.replace(":id", this.selectedUser.id),
        method: "PUT",
        data,
      });

      const res = result.data;
      if (res.code == 200 && res.success) {
        this.showEditModal = false;
        this.$swal({ text: "Cập nhật thành công" });
        this.$emit("users:updated");
      }
    },

    formatDate,
    formatTime,
  },

  watch: {
    open(newVal) {
      this.showEditModal = newVal;
    },
    user(newVal) {
      // Transform arr -> object
      this.showEditModal = true;
      const newUser = JSON.parse(JSON.stringify(newVal));
      let roles = null;
      for (let role of newUser.roles) {
        roles = role["role__id"];
      }
      this.selectedUser = newUser;
      this.selectedUser.roles = roles;
    },
    showEditModal(newVal) {
      if (!newVal) {
        this.$emit("showEditModal:closed");
      }
    },
  },
};
</script>
<style scoped>
.height-table-expand {
  height: 400px;
}
</style>
