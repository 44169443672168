<template>
  <div class="relative">
    <el-container class="gap-3">
      <el-aside class="bottom-0 h-full">
        <el-menu class="pt-3">
          <el-submenu>
            <!-- Role -->
            <el-menu-item-group>
              <template #title><span class="font-bold">Vai trò</span></template>
              <el-menu-item
                v-for="(role, id) in roles"
                :class="{'is-active': selectedRole['id'] == id }"
                :index="role['permissions']"
                :key="role['permissions']"
                @click="showRole(role)"
              >
                {{ role['name'] }}
              </el-menu-item>
              <el-menu-item class="" index="add-role">
                <div
                  class="
                    flex
                    items-center
                    justify-center
                    h-full
                    w-full
                    font-bold
                    text-sm
                  "
                  @click="addRole"
                >
                  <i class="el-icon-plus"></i>
                  Thêm vai trò
                </div>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>

      <div class="flex flex-col w-full gap-2 relative" v-if="selectedRole.id">
        <div
          class="sticky top-0 p-3 bg-white italic border border-gray-200 z-10"
        >
          <div class="flex justify-start font-bold">
            <el-input class="role-title" v-model="selectedRole.name"></el-input>
          </div>
        </div>
        <div
          class="w-full"
          v-for="(permissionGroup, group, idx) in permissionGroups"
          :key="permissionGroup['name']"
        >
          <el-card class="box-card">
            <template #header class="bg-gray-400">
              <div class="flex justify-between items-center">
                <span class="font-bold">{{idx + 1}}. {{ permissionGroup["name"] }}</span>
                <el-checkbox
                  v-model="selectedPermissions[group]"
                  @change="
                    toggleCheckAll($event, permissionGroup['permissions'])
                  "
                >
                  Toàn bộ
                </el-checkbox>
              </div>
            </template>
            <div class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
              <div
                v-for="permission in permissionGroup['permissions']"
                :key="permission[0]"
              >
                <el-checkbox
                  v-model="selectedPermissions[permission[0]]"
                  @change="checkSelectAll"
                >
                  {{ permission[1] }}
                </el-checkbox>
              </div>
            </div>
          </el-card>
        </div>

        <div
          class="
            sticky
            bottom-0
            right-0
            p-3
            bg-white
            mt-3
            border border-gray-200
            z-10
          "
        >
          <div class="flex justify-between">
            <el-button type="danger" size="mini" @click="removeRole">
              Xóa
            </el-button>
            <el-button type="primary" size="mini" @click="editRole">
              Lưu
            </el-button>
          </div>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      permissionGroups: {},
      roles: [],
      groups: ["group1"],

      selectedRole: {
        id: null,
        name: null,
        permissions: {},
      },
      selectedPermissions: {},
    };
  },

  computed: {
    ...mapGetters(["getSelectedCompany"]),
  },

  async created() {
    await this.getBasePermissons();
    const roleKeys = Object.keys(this.roles);
    if (roleKeys.length) {
      this.showRole(this.roles[roleKeys[0]])
    }
  },

  methods: {
    async getBasePermissons() {
      const params = {
        company_id: this.getSelectedCompany.id,
      };

      const result = await this.$request({
        url: "/users/permissions",
        params,
      });
      const res = result.data;

      if (res.success && res.code == 200) {
        this.roles = res.data.owner_roles;
        this.permissionGroups = res.data.permissionGroups;
      }
    },

    async showRole(role) {
      // Init form check-box
      for (let permission in this.selectedPermissions) {
        this.selectedPermissions[permission] = false;
      }
      for (let permission of role.permissions) {
        this.selectedPermissions[permission] = true;
      }

      // Check-all init
      this.selectedRole = {
        id: role.id,
        name: role.name,
        permissions: this.selectedPermissions,
      };

      this.checkSelectAll();
    },

    checkSelectAll() {
      for (let group in this.permissionGroups) {
        this.selectedPermissions[group] = false;
      }
      const groupCounts = [];
      Object.keys(this.permissionGroups).map((item) => {
        groupCounts[item] = this.permissionGroups[item].permissions.length;
        return item;
      });
      for (let permission in this.selectedPermissions) {
        if (this.selectedPermissions[permission]) {
          const permissionPrefix = permission.split(".")[0];
          groupCounts[permissionPrefix]--;
        }
      }
      for (let checkGroup in groupCounts) {
        this.selectedPermissions[checkGroup] = groupCounts[checkGroup] == 0;
      }
    },

    async addRole() {
      const data = {
        company_id: this.getSelectedCompany.id,
        owner_id: this.getSelectedCompany.owner.id,
      };
      const result = await this.$request({
        url: "/users/permissions",
        method: "POST",
        data,
      });

      const res = result.data;
      if (res.success && res.code == 200) {
        await this.getBasePermissons();
        this.$swal({ html: `Cập nhật thành công vai trò <b>Vai trò mới</b>` });
      }
    },

    async editRole() {
      const result = await this.$request({
        url: "/users/permissions",
        method: "PUT",
        data: {
          ...this.selectedRole,
          company_id: this.getSelectedCompany.id,
        }

      });

      const res = result.data;
      if (res.success && res.code == 200) {
        await this.getBasePermissons();
        this.$swal({
          html: `Cập nhật thành công vai trò <b>${this.selectedRole.name}</b>`,
        });
      }
    },

    removeRole() {
      this.$swal({
        title: "Xác nhận xóa vai trò",
        html: `Thao tác này sẽ xóa vai trò: <b>${this.selectedRole.name}</b>`,
        icon: "warning",
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        showCancelButton: true,
        reverseButtons: true,
        timer: 15000,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const result = await this.$request({
            url: "/users/permissions",
            method: "DELETE",
            data: {
              ...this.selectedRole,
              company_id: this.getSelectedCompany.id,
            }
          });

          const res = result.data;
          let text, icon = "";
          let timer = 3000;
          if (res.code == 400) {
            text = res.messages;
            icon = "warning";
            timer = 10000;
            this.$swal({ text, icon, timer });
          }
          if (res.code == 200) {
            text = "Xóa vai trò thành công";
            icon = "success";
            this.getBasePermissons();
            this.$swal({ text, icon, timer });
          }
        }
        this.isRemoveUser = false;
      });
      return "";
    },

    toggleCheckAll(checked, permissions) {
      for (let permission of permissions) {
        this.selectedPermissions[permission[0]] = checked;
      }
    },
  },

  watch: {
    getSelectedCompany(newVal) {
      // Owner id do not exists => Redirect to User Tab
      if (!newVal.owner) {
        location.reload();
        return;
      }
      this.roleKeys = [];
      this.selectedRole = {};
      this.permissionGroups = {};
      this.getBasePermissons();
    },
  },
};
</script>
<style scoped>
::v-deep(.el-card__header) {
  background: #f7f9fc;
  padding: 0.5rem 1rem;
}

.role-title {
  font-size: 1.2rem;
  font-weight: bold;
  font-style: italic;
}

.el-aside {
  color: #333;
}
.is-active {
  background: #ecf5ff;
  font-weight: bold;
}
.el-menu-item {
  height: 2.5rem;
  line-height: 2.75;
}
::v-deep(.el-card__body), ::v-deep(.el-card__header) {
  padding: 0.25rem 1rem;
}
</style>
