import { mapGetters, mapActions } from "vuex";
import Role from "@/views/pages/users/role/role.vue";
import UserList from "@/views/pages/users/user/list.vue";

export default {
  components: {
    Role,
    UserList,
  },
  data() {
    return {
      tabSelected: "user", // [user, role]
    };
  },

  async created() {},

  methods: {
    ...mapActions(["loadingLayer"]),
  },

  mounted() {},

  computed: {
    ...mapGetters([
      "companies",
      "getUserSearch",
      "getUserRole",
      "getSelectedCompany",
      "getAuthUser",
    ]),

    ableToAddUser() {
      return (
        this.getSelectedCompany.id != -1 &&
        (this.getAuthUser.is_itax_admin ||
          this.getSelectedCompany.owner.id == this.getAuthUser.id)
      );
    },
  },

  watch: {
    getUserSearch(newVal) {
      this.search = newVal;
      this.searchInput();
    },
  },
};
