function convertTaxNumberToText(vat, is_no_tax = false) {
  if (is_no_tax && vat == 0) {
    return "KCT";
  } else if (vat == -1) {
    return "KKKNT";
  } else if (vat != null) {
    return vat + "%";
  }
  return "";
}

function getInvoiceStatus(invoice) {
  if (invoice) {
    if (invoice.lookupinfo__invoice_status == "1") {
      return "Hóa đơn mới";
    } else {
      try {
        return invoice.lookupinfo__invoice_note
          .match(/Trạng thái hóa đơn: ([^\\.]*)/)[1]
          .replace(/(<([^>]+)>)/gi, "");
      } catch {
        return "";
      }
    }
  }
}

function durationReadAble(startAt, endAt) {
  let seconds = Math.floor((new Date(endAt) - new Date(startAt)) / 1000);
  var interval = seconds / 31536000;
  let result = "";

  if (interval > 1) {
    result += Math.floor(interval) + " năm ";
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    result += interval + " tháng ";
    seconds -= interval * 2592000;
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    result += interval + " ngày ";
    seconds -= interval * 86400;
  }

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    result += interval + " giờ ";
    seconds -= interval * 3600;
  }

  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    result += interval + " phút ";
    seconds -= interval * 60;
  }

  if (!result && seconds) {
    result += " < 1 phút";
  }
  return result;
}

// Apply only for el-table
function styleChecker({ row, column }) {
  if (
    column.property == "report_period" &&
    row["colors"] &&
    row["colors"].length > 0
  ) {
    const colors = row["colors"];
    return `
      background-repeat: no-repeat;
      background-size: 15px auto;
      background-image: ${buildColors(colors)};
    `;
  }
}

function labelBackgroundStyle(colors) {
  return `
    background-repeat: no-repeat;
    background-size: 15px auto;
    background-image: ${buildColors(colors)};
  `;
}

function buildColors(colors) {
  const smallestPart = parseInt(100 / colors.length);
  let result = `linear-gradient(to bottom, ${colors.at(0)} ${smallestPart}%`;
  let i = 1;
  for (; i < (colors.length - 2) * 2 + 1; i++) {
    if (smallestPart * i < 95) {
      result += `, ${colors.at(i)} ${smallestPart * i}%`;
    }
    if (smallestPart * (i + 1) < 95) {
      result += `, ${colors.at(i)} ${smallestPart * (i + 1)}%`;
    }
  }
  if (i == 1) {
    result += `, ${colors.at(colors.length - 1)} ${smallestPart}%`;
  }
  result += `);`;
  return result;
}

function hashCode(obj) {
  obj = JSON.stringify(obj);
  var hash = 0,
    i,
    chr;
  if (obj.length === 0) return hash;
  for (i = 0; i < obj.length; i++) {
    chr = obj.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

function stringToSlug(str) {
  // remove accents
  var from =
      "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
    to =
      "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(RegExp(from[i], "gi"), to[i]);
  }

  /* eslint-disable */
  str = str.toLowerCase().trim();

  return str;
}

function buildMaximumDateRange(dateRange, total_invoices) {
  if (!dateRange) return;
  let maximum_range_in_days = 31 * 12;
  if (total_invoices > 100000) {
    maximum_range_in_days = 31 * 1;
  } else if (total_invoices > 70000) {
    maximum_range_in_days = 31 * 1;
  } else if (total_invoices > 50000) {
    maximum_range_in_days = 31 * 6;
  }
  const start = new Date(dateRange[0]);
  const end = new Date(dateRange[1]);
  if (
    end.getTime() - start.getTime() >
    3600 * 1000 * 24 * maximum_range_in_days
  ) {
    start.setTime(end.getTime() - 3600 * 1000 * 24 * maximum_range_in_days);
  }
  return [start, end];
}

function validateDateRangeFTS(dateRange, total_invoices) {
  if (!dateRange) return;
  let maximum_range_in_days = 31 * 12;
  if (total_invoices > 100000) {
    maximum_range_in_days = 7;
  } else if (total_invoices > 50000) {
    maximum_range_in_days = 14;
  } else if (total_invoices > 20000) {
    maximum_range_in_days = 31 * 3;
  }

  const start = new Date(dateRange[0]);
  const end = new Date(dateRange[1]);
  const isInvalid =
    end.getTime() - start.getTime() > 3600 * 1000 * 24 * maximum_range_in_days;
  if (isInvalid) {
    return `Các kí tự màu đỏ cần đổi khoảng thời gian về < ${maximum_range_in_days} ngày để có thể tìm kiếm.`;
  }
  return "";
}

function buildShortcut(total_invoices) {
  let shortcuts = [
    {
      text: "1 tháng trước",
      value: () => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        return [start, end];
      },
    },
    {
      text: "6 tháng trước",
      value: () => {
        const end = new Date();
        const start = new Date();
        start.setMonth(start.getMonth() - 6);
        return [start, end];
      },
    },
    {
      text: `Năm ${new Date().getFullYear()}`,
      value: () => {
        const end = new Date();
        const start = new Date(new Date().getFullYear(), 0);
        return [start, end];
      },
    },
    {
      text: `Năm ${new Date().getFullYear() - 1}`,
      value: () => {
        const end = new Date(new Date().getFullYear() - 1, 11);
        const start = new Date(new Date().getFullYear() - 1, 0);
        return [start, end];
      },
    },
  ];

  if (total_invoices > 100000) {
    shortcuts = [
      {
        text: "1 tuần trước",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        },
      },
      {
        text: "2 tuần trước",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
          return [start, end];
        },
      },
      {
        text: "1 tháng trước",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        },
      },
    ];
  } else if (total_invoices > 50000) {
    shortcuts = [
      {
        text: "1 tháng trước",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        },
      },
      {
        text: "3 tháng trước",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        },
      },
    ];
  }

  return shortcuts;
}

export {
  convertTaxNumberToText,
  getInvoiceStatus,
  durationReadAble,
  buildColors,
  styleChecker,
  labelBackgroundStyle,
  hashCode,
  stringToSlug,
  buildMaximumDateRange,
  validateDateRangeFTS,
  buildShortcut,
};
