<template>
  <div>
    <div class="itax-button">
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="openAddUser()"
        v-if="getSelectedCompany.id != -1 && (getAuthUser.is_itax_admin || (getSelectedCompany.owner && getAuthUser.id == getSelectedCompany.owner.id))"
      >
        Thêm người dùng
      </el-button>
    </div>

    <!-- Add User Modal -->
    <el-dialog title="Thêm người dùng" v-model="showAddUserModal">
      <el-form @submit.prevent="() => {}">
        <el-form-item class="w-full relative" label="Email" label-width="70px">
          <el-input
            v-model="userForm.emailQuanLyTaiKhoan"
            name="hoTen"
            required
          />
          <span v-if="isEmail" class="absolute text-xs text-red-400">
            {{ errorEmail }}
          </span>
        </el-form-item>

        <el-form-item class="w-full" label="Vai trò" label-width="70px">
          <el-radio-group v-model="userForm.roles" class="grid grid-cols-3">
            <el-radio
              v-for="(role, idx) in roles"
              :key="role['id']"
              :label="idx"
            >
              {{ role["name"] }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <div class="dialog-footer itax-button text-right">
          <el-button size="mini" @click="showAddUserModal = false">
            Hủy
          </el-button>
          <el-button size="mini" type="primary" @click="confirmAddUser">
            Xác nhận
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { URL } from "@/api/users";
import formatDate from "@/plugins/formatDate.js";
import formatTime from "@/plugins/formatTime.js";
import { validateEmail } from "@/plugins/rules.js";

export default {
  components: {},
  data() {
    return {
      userForm: {
        roles: {},
      },
      showAddUserModal: false,
    };
  },

  props: {
    roles: {
      type: Array,
      default: null,
    },
  },

  computed: {
    ...mapGetters(["getSelectedCompany", "getAuthUser"]),
  },

  async created() {
    this.search = this.getUserSearch;
  },

  methods: {
    openAddUser() {
      this.showAddUserModal = true;
      this.userForm["roles"] = null;

      // Select Member as Default ROle
      for (let id in this.roles) {
        if (this.roles[id]["name"] == "Member") {
          this.userForm.roles = id;
        }
      }
    },

    async confirmAddUser() {
      // Validate
      if (!validateEmail(this.userForm.emailQuanLyTaiKhoan)) {
        this.$swal({
          text: "Vui lòng nhập đúng định dạng email",
          icon: "warning",
        });
        return;
      }
      if (!this.userForm.roles) {
        this.$swal({ text: "Vui lòng chọn vai trò", icon: "warning" });
        return;
      }

      const data = {
        company_id: this.getSelectedCompany.id,
        role_ids: [this.userForm.roles],
        emailQuanLyTaiKhoan: this.userForm.emailQuanLyTaiKhoan,
      };
      const result = await this.$request({
        url: URL.CREATE_USER,
        method: "POST",
        data,
      });
      const res = result.data;
      if (res.code == 200) {
        this.$swal({ text: "Tạo thành công" });
        this.$emit("users:updated");

        this.showAddUserModal = false;
      }
      if (res.code == 400) {
        this.$swal({ text: res.messages, icon: "warning" });
      }
    },

    formatDate,
    formatTime,
  },

  watch: {
    getSelectedCompany() {
      this.userForm = {};
      this.userForm = {
        roles: {},
      };
    },
  },
};
</script>
