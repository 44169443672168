<div class="block lg:flex justify-between items-center mb-3 lg:mb-0">
  <el-tabs v-model="tabSelected">
    <el-tab-pane label="Người dùng" name="user" class="w-full" />
    <el-tab-pane
      label="Vai trò và Quyền"
      v-if="ableToAddUser"
      name="role"
    />
  </el-tabs>
</div>

<UserList v-if="tabSelected == 'user'" />
<Role v-if="tabSelected == 'role'" />
